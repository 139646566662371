<template>
  <h2>Estoy en soportes</h2>
</template>

<script>
export default {
  name: 'supportIndex'
}
</script>

<style scoped>

</style>
